import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { updateNameInput, toggleEdit } from '../actions/nameInput';
import { createOrUpdatePosition, isEditMode } from '../actions/position';
import { isEmpty } from '../util/utils';

class AnalysisName extends React.Component {
  constructor(props) {
    super(props);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.getName = this.getName.bind(this);
  }

  handleEdit() {
    const { dispatch } = this.props;

    dispatch(toggleEdit());
  }

  handleChange(event) {
    const { dispatch } = this.props;

    dispatch(updateNameInput({newValue: event.target.value}));
  }

  handleKeyPress(event) {
    if (event.key === 'Enter') {
      this.handleSave()
    }
  }

  handleSave() {
    const { position, nameInput, dispatch } = this.props;

    dispatch(toggleEdit());
    const json = { name: nameInput.newValue };
    dispatch(createOrUpdatePosition(position, json));
  }

  getName() {
    const { position, nameInput } = this.props;

    if(nameInput?.newValue || nameInput?.newValue === '') return nameInput.newValue;

    return position.name || position.id;
  }

  render() {
    const { nameInput, position } = this.props;
    const nameString = this.getName();

    if (!isEditMode()) return ''
    if (isEmpty(position)) return ''

    return (
      <h5>
        <span>Name</span>

        <small className="ml-1">
          {nameInput.edit ? (
            <>
              <input
                name="name"
                type="text"
                value={nameString}
                onChange={this.handleChange}
                onKeyPress={this.handleKeyPress}
                className="form-control"
              />
              <button type="button" className="btn btn-primary btn-sm" onClick={this.handleSave}>Save</button>
            </>
          ) : (
            nameString
          )}
        </small>

        {!nameInput.edit && (
          <svg
            onClick={this.handleEdit}
            className="bi bi-pencil"
            width="25"
            height="25"
            viewBox="0 0 20 20"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M13.293 3.293a1 1 0 011.414 0l2 2a1 1 0 010 1.414l-9 9a1 1 0 01-.39.242l-3 1a1 1 0 01-1.266-1.265l1-3a1 1 0 01.242-.391l9-9zM14 4l2 2-9 9-3 1 1-3 9-9z"
              clipRule="evenodd"
            />
            <path
              fillRule="evenodd"
              d="M14.146 8.354l-2.5-2.5.708-.708 2.5 2.5-.708.708zM5 12v.5a.5.5 0 00.5.5H6v.5a.5.5 0 00.5.5H7v.5a.5.5 0 00.5.5H8v-1.5a.5.5 0 00-.5-.5H7v-.5a.5.5 0 00-.5-.5H5z"
              clipRule="evenodd"
            />
          </svg>
        )}
      </h5>
    );
  }
}

AnalysisName.propTypes = {
  dispatch: PropTypes.func.isRequired,
  nameInput: PropTypes.shape({
    edit: PropTypes.bool,
    newValue: PropTypes.string,
  }),
  position: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
};

function mapStateToProps(state) {
  const { nameInput, position } = state;

  return { nameInput, position };
}

export default connect(mapStateToProps)(AnalysisName);
