import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { createOrUpdatePosition } from '../actions/position';
import { updateCastling } from '../actions/castling';
import { toggleEdit, updateFenInput } from '../actions/fenInput';

class AnalysisFen extends React.Component {
  constructor(props) {
    super(props);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  handleEdit() {
    const { dispatch } = this.props;

    dispatch(toggleEdit());
  }

  handleChange(event) {
    const { dispatch } = this.props;

    dispatch(updateFenInput({newValue: event.target.value}));
  }

  handleKeyPress(event) {
    if (event.key === 'Enter') {
      this.handleSave()
    }
  }

  handleSave() {
    const { dispatch, fenInput, position } = this.props;

    chessground.set({ fen: fenInput.newValue.trim() });

    dispatch(toggleEdit());
    const json = { moves: position.moves.concat([chessground.getFen()]) }
    dispatch(createOrUpdatePosition(position, json))
    dispatch(updateCastling(chessground));
  }

  getFenValue() {
    const { fenInput } = this.props;
    if (fenInput?.newValue || fenInput?.newValue === '') return fenInput.newValue;

    if (typeof (chessground) !== 'undefined') return chessground.getFen();

    return '';
  }

  render() {
    const { fenInput } = this.props;
    const fenValue = this.getFenValue();

    return (
      <h5>
        <span>FEN</span>
        <small className="ml-1">
          {fenInput.edit ? (
            <>
              <input
                name="fen"
                type="text"
                value={fenValue}
                onChange={this.handleChange}
                onKeyPress={this.handleKeyPress}
                className="form-control"
                placeholder="Paste FEN"
              />
              <button type="button" className="btn btn-primary btn-sm" onClick={this.handleSave}>Save</button>
            </>
          ) : (
            fenValue
          )}
        </small>

        {!fenInput.edit && (
          <svg
            onClick={this.handleEdit}
            className="bi bi-pencil"
            width="25"
            height="25"
            viewBox="0 0 20 20"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M13.293 3.293a1 1 0 011.414 0l2 2a1 1 0 010 1.414l-9 9a1 1 0 01-.39.242l-3 1a1 1 0 01-1.266-1.265l1-3a1 1 0 01.242-.391l9-9zM14 4l2 2-9 9-3 1 1-3 9-9z"
              clipRule="evenodd"
            />
            <path
              fillRule="evenodd"
              d="M14.146 8.354l-2.5-2.5.708-.708 2.5 2.5-.708.708zM5 12v.5a.5.5 0 00.5.5H6v.5a.5.5 0 00.5.5H7v.5a.5.5 0 00.5.5H8v-1.5a.5.5 0 00-.5-.5H7v-.5a.5.5 0 00-.5-.5H5z"
              clipRule="evenodd"
            />
          </svg>
        )}
      </h5>
    );
  }
}

AnalysisFen.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const { fenInput, position } = state;

  return { fenInput, position };
}

export default connect(mapStateToProps)(AnalysisFen);
