import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { updateMoveNum } from '../actions/moveNum';
import { isEmpty } from '../util/utils';

class AnalysisNavButtons extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(event) {
    const { value } = event.target;
    const { dispatch, position } = this.props;
    let { moveNum } = this.props;

    switch (value) {
      case 'first':
        moveNum = 0;
        break;
      case 'prev':
        moveNum -= 1;
        break;
      case 'next':
        moveNum += 1;
        break;
      case 'last':
        moveNum = position.moves.length - 1;
        break;
      default:
        moveNum = 0;
    }

    chessground.set({ fen: position.moves[moveNum] });
    dispatch(updateMoveNum(moveNum));
  }

  render() {
    const { position, moveNum } = this.props;

    if(isEmpty(position)) return (null);

    return (
      <div className="btn-group p-1">
        <button type="button" className="btn btn-info mr-3" onClick={this.handleClick} value="first" disabled={moveNum === 0}>
          &lt;&lt;
        </button>
        <button type="button" className="btn btn-info mr-3" onClick={this.handleClick} value="prev" disabled={moveNum === 0}>
          &lt;
        </button>
        <button type="button" className="btn btn-info mr-3" onClick={this.handleClick} value="next" disabled={moveNum === position.moves.length - 1}>
          &gt;
        </button>
        <button type="button" className="btn btn-info mr-3" onClick={this.handleClick} value="last" disabled={moveNum === position.moves.length - 1}>
          &gt;&gt;
        </button>
      </div>
    );
  }
}

AnalysisNavButtons.propTypes = {
  dispatch: PropTypes.func.isRequired,
  position: PropTypes.shape({
    id: PropTypes.string,
    moves: PropTypes.arrayOf(PropTypes.string).isRequired,
  }),
  moveNum: PropTypes.number,
};

function mapStateToProps(state) {
  const { position, moveNum } = state;

  return { position, moveNum };
}

export default connect(mapStateToProps)(AnalysisNavButtons);
